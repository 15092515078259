import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import Header from './Components/Header';
import Index from './Components/Index';
import PP from './Components/PP';
import Form from './Components/Form';
import Update from './Components/Update';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="*" element={<Index />} />
          <Route path="/:parameter/" element={<PP />} />
          <Route path="/become-a-azaadi-razakar" element={<Form />} />
          <Route path="/update-info" element={<Update />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
