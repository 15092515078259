import React from "react";

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <div className="container-fluid" id="footer">
                <div className="row justify-content-center pt-3 pb-2 text-white">
                    <h6 className="font-weight-bold">CopyRights © {year} All Rights Reserved</h6>
                </div>
            </div>
        </>
    )
}