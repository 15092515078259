import React from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import $ from 'jquery';
import logo from '../Images/logo2.png';
import main from '../Images/main-image2.png';

export default function Update() {
    const [lstpp, setpp] = useState([]);
    useEffect(() => {
        axios
            .get("https://api.azadirazakar.com/api/Home/GetProvinceDetails")
            .then(response => setpp(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(lstpp);

    var a = window.location.search;
    if (a.includes("pp=")) {
        var b = a.replace('?pp=', '');
        $(".select2 option:selected").val(b);
    }

    $('#sltpp').select2();

    const chngEng = () => {
        // $("#h3").text("Update Form");
        // $("#h4").text("Azaadi Razakar Banye");
        $("#h4").text("Update your PP");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text("Name:").css("float", "left");
        $("#lcnic").text("CNIC:").css("float", "left");
        $("#lpp").text("PP:").css("float", "left");
        $("#lbcode").text("Message your Identity Card number to 8300 for block code.").css("text-align", "start").css("float", "left");
        $("#lmobile").text("Mobile Number:").css("float", "left");
        $("#sub").text("Submit");
        $("#txtwht").text("Do You Want to join Whatsapp Group? ");
        $("#lblnk").css("float", "left");
        $("#urdemail").addClass("d-none");
        $("#engemail").removeClass("d-none");
    }

    const chngUrd = () => {
        // $("#h3").text("اپ ڈیٹ فارم");
        // $("#h4").text("آزادی رضاکار بنیے");
        $("#h4").text("اگر آپ نے پہلے اپنے حلقے کا اندراج غلط کیا ہے تو آپ اس فارم سے اپڈیٹ کر سکتے ہیں");
        $("#en").text("English");
        $("#ur").text("اردو");
        $("#lname").text(":نام").css("float", "right");
        $("#lcnic").text(":قومی شناختی کارڈ").css("float", "right");
        $("#lpp").text(":حلقہ").css("float", "right");
        $("#lbcode").text("بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں۔").css("text-align", "end").css("float", "right");
        $("#lmobile").text(":موبائل نمبر").css("float", "right");
        $("#sub").text("جمع کرائیں");
        $("#txtwht").text(" کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟");
        $("#lblnk").css("float", "right");
        $("#urdemail").removeClass("d-none");
        $("#engemail").addClass("d-none");
    }

    var opt = "";
    $('#sltpp').on('change', function (e) {
        opt = $("#sltpp :selected").text();
        if (opt != "Select") {
            // var url = document.location.search + "?";
            // url = url.substring(0, url.indexOf('?') + 1);
            // window.history.pushState({}, "", url + "pp=" + opt.replace(/ /g, '').toLowerCase());
            $("#lblnk").show();
            $("#br").show();
            $("#brr").show();
            $("#lbpp").text($("#sltpp :selected").text());
            $("#whtlink").val("https://www.whatsapplink.com?" + opt.replace(/ /g, '').toLowerCase());
            $(".select2-selection--single").css("border", "");
        }
        else {
            $("#lblnk").hide();
            $("#br").hide();
            $("#brr").hide();
            $(".select2-selection--single").css("border", "3px solid red");
        }
    });

    const chkpp = () => {
        if ($("#chkpp").is(':checked')) {
            $("#whtlink").show();
        }
        else {
            $("#whtlink").hide();
        }
    }

    function validateCnic() {
        document.getElementById('cnic').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
            e.target.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
        var cnic = $("#cnic").val();
        if (cnic == "" || cnic == null) {
            $("#cnic").css("border", "3px solid red");
        } else {
            if (cnic.length < 15 || cnic.length > 15) {
                $("#cnic").css("border", "3px solid red");
            } else {
                $("#cnic").css("border", "");
            }
        }
    }

    // function validatePP() {
    //     var pp = $("#sltpp option:selected").text();
    //     if (pp == "Select"){
    //         $(".select2-selection--single").css("border", "3px solid red");
    //     } else {
    //         $(".select2-selection--single").css("border", "");
    //     }
    // }

    const Update = () => {
        var whatAppLink = $("#sltpp").val();
        var pp = $("#sltpp option:selected").text();
        //var name = $("#name").val();
        var cnic = $("#cnic").val();
        //var mobile = $("#mobile").val();

        if (pp == "Select" || cnic == "") {
            validateCnic();
            $('#sltpp').change();
            //validatePP();
            return;
        } else if (cnic.length < 15 || cnic.length > 15) {
            validateCnic();
            $("#lblcnic").text("Enter valid CNIC");
            return;
        }
        $("#lblcnic").text("");

        var obj = {
            PP: pp,
            //Name: name,
            Cnic: cnic,
            //Mobile: mobile
        }

        let json = JSON.stringify(obj);
        fetch('https://api.azadirazakar.com/api/Home/UpdateRazakarData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json
        })
            .then(response => {
                //console.log(response.ok);
                if (response.ok == true) {
                    //alert("Updated Successfully");
                    window.location.href = whatAppLink;
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Success",
                    //     content: "Your Message Is Submit Successfully",
                    //     type: 'green',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-green',
                    //             action: function () {
                    //                 window.location.reload();
                    //             }
                    //         }
                    //     }
                    // });
                }
                else {
                    alert("Failed to update");
                    // $.confirm({
                    //     theme: 'material',
                    //     title: "Error",
                    //     content: "Failed To Submit Message",
                    //     type: 'red',
                    //     typeAnimated: true,
                    //     buttons: {
                    //         tryAgain: {
                    //             text: 'OK',
                    //             btnClass: 'btn-red',
                    //             action: function () {

                    //             }
                    //         }
                    //     }
                    // });
                    return;
                }
            })
            .catch(error => {
                //console.error(error);
                alert("Failed to update");
                // $.confirm({
                //     theme: 'material',
                //     title: "Error",
                //     content: error,
                //     type: 'red',
                //     typeAnimated: true,
                //     buttons: {
                //         tryAgain: {
                //             text: 'OK',
                //             btnClass: 'btn-red',
                //             action: function () {

                //             }
                //         }
                //     }
                // });
                return;
            });
    }

    // const Cnic = () => {
    //     document.getElementById('cnic').addEventListener('input', function (e) {
    //         var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})(\d{0,1})/);
    //         e.target.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
    //     });
    // }

    return (
        <>
            <Helmet>
                <title>اگر آپ نے پہلے اپنے حلقے کا اندراج غلط کیا ہے تو آپ اس فارم سے اپڈیٹ کر سکتے ہیں</title>
            </Helmet>
            <div className="container-fluid" id="main-container3">
                <div className="row">
                    <div className="col-md-7 col-6">
                        <a class="navbar-brand d-md-block d-none p-0" href="/" style={{ width: '20%' }}>
                            <img src={logo} id="logo" />
                        </a>
                        {/* <div className="row mb-5">
                            <nav class="navbar navbar-expand-lg navbar-dark">
                                <a class="navbar-brand" href="/" style={{ width: '30%' }}>
                                    <img src={logo} id="logo" />
                                </a>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav" style={{ marginLeft: '30%' }}>
                                        <li class="nav-item active">
                                            <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                                Province
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/punjab/">Punjab</a>
                                                <a class="dropdown-item" href="/sindh/">Sindh</a>
                                                <a class="dropdown-item" href="/kpk/">KPK</a>
                                                <a class="dropdown-item" href="/blochistan/">Blochistan</a>
                                            </div>
                                        </li>
                                        <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" role="button" href="/become-a-azaadi-razakar" aria-expanded="false">
                                                Form
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="/update-info">Update Info</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div> */}
                    </div>
                    <div className="col-md-4 text-md-center mx-md-auto col-6 overflow-hidden">
                        <img src={main} id="mainImg" />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-white col-11 bg-white px-5 pb-5" id='main-container5'>
                        {/* <h3 className="font-weight-bold text-center text-white" id='h3'>اپ ڈیٹ فارم</h3> */}
                        {/* <h4 className="font-weight-bold text-center text-white" id="h4">آزادی رضاکار بنیے</h4> */}
                        <div className='text-center'><img src={logo} style={{ width: '20%' }} /></div>
                        <h4 className="font-weight-bold text-center text-white mt-0" style={{ fontSize: '20px', marginTop: '34px' }} id="h4">
                            اگر آپ نے پہلے اپنے حلقے کا اندراج غلط کیا ہے تو آپ اس فارم سے اپڈیٹ کر سکتے ہیں
                        </h4>
                        <br />
                        <button className="btn btn-success my-2" id="en" onClick={() => chngEng()}>English</button>&nbsp;
                        <button style={{ float: 'right' }} className="btn btn-danger" id="ur" onClick={() => chngUrd()}>اردو</button>
                        <br />
                        <label className="font-weight-bold" style={{ float: 'right' }} id="lcnic">:قومی شناختی کارڈ</label>
                        <input type="text" className="form-control" placeholder="_____-_______-_" id="cnic" onKeyUp={() => validateCnic()} />
                        <label id="lblcnic" className="text-warning"></label>
                        <br />
                        <label className="font-weight-bold" style={{ float: 'right' }} id="lpp">:حلقہ</label>
                        <select className="form-control select2" id="sltpp">
                            <option value="">Select</option>
                            {
                                lstpp.length > 0 ?
                                    lstpp.map((p) =>
                                        <option value={p.whatAppLink}>{p.constituency.replace(/-/g, '')}</option>
                                    )
                                    : ""
                            }
                        </select>
                        <br /><br />
                        {/* <label className="font-weight-bold" id="lblnk" style={{ display: 'none', float: 'right' }}>
                            <span id="txtwht">کیا آپ واٹس ایپ گروپ جوائن کرنا چاہتے ہیں؟</span> <input type="checkbox" id="chkpp" onChange={(e) => chkpp(e)} />&nbsp;
                            <br />
                            <span id="lbpp"></span>
                        </label>
                        <input type="text" className="form-control" id="whtlink" style={{ display: 'none' }} />
                        <br id="br" style={{ display: 'none' }} />
                        <br id="brr" style={{ display: 'none' }} /> */}
                        {/* <label className="font-weight-bold" style={{ float: 'right' }} id="lname">:نام</label>
                        <input type="text" className="form-control" />
                        <br /> */}
                        {/* <br/>
                        <label className="font-weight-bold" style={{ textAlign: 'end', float: 'right' }} id="lbcode">بلاک کوڈ کے لیے اپنا شناختی کارڈ نمبر 8300 پر میسج کریں</label>
                        <input type="number" className="form-control" /> 
                        <br />*/}
                        {/* <label className="font-weight-bold" style={{ float: 'right' }} id="lmobile">:موبائل نمبر</label>
                        <input type="number" placeholder="____-_______" className="form-control" />
                        <br /> */}
                        <button className="btn btn-block btn-success" id="sub" onClick={() => Update()}>جمع کرائیں</button>
                        <br />
                        <small id="engemail" className="float-left d-none">For any issue contact on email: mmazadirazakar@gmail.com</small>
                        <small id="urdemail" className="float-right">mmazadirazakar@gmail.com :کسی بھی مسئلے کے لیے ای میل پر رابطہ کریں</small>
                    </div>
                </div>
            </div>
            <br /><br />
        </>
    )
}